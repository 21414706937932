import React from "react";
import './App.css';

class App extends React.Component {

  componentDidMount() {
    // 対象となる要素をすべて変数へ格納
    const fadeTargets = document.querySelectorAll(".animation-fade");

    // オプション
    // root : nullの場合はブラウザのビューポート
    // rootMargin : 0とすると↑で指定したルート要素と交差した瞬間（1pxでも）発火。今回のように-50%とすると対象要素がルート要素であるブラウザのビューポートの半分のところで交差したら発火となる。ルート要素を基準としたオプション。
    // threshold : 閾値（しきいち）。どのくらい交差したら発火するかを指定する。0とすれば交差した瞬間に発火。対象要素を基準としたオプション。
    // rootMarginとthresholdで交差してから（可視範囲に入ってから）どの程度で発火するかを調整できるということ。
    const fadeOption = {
      root: null,
      rootMargin: "-20% 0px",
      threshold: [0]
    };

    // 対象となるターゲットが可視範囲に入ったらactiveというクラスを付与する関数
    const targets = (entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add("active");
        }
      });
    };

    // 可視範囲に入ったら実行する関数targetsとfadeOptionを引数にしてIntersectionObserverコンストラクターを呼び出し
    const fadeObserver = new IntersectionObserver(targets, fadeOption);

    // fadeTargetsのすべての要素を監視印象に登録
    fadeTargets.forEach((target) => {
      fadeObserver.observe(target);
    });
  }

  render() {
    return (
      <div className="App">
        <header>
          {/* <div className="inner">
            <h1 className="logo">
              <div className="animation-fade up">
                <p className="font__red">30代からのサプリメント</p>
                <figure><img src="./assets/img/logo@2x.png" alt="logo" /></figure>
              </div>
            </h1>
          </div> */}
        </header>

        <section id="section01">
          <div className="inner">
            <div className="flex">
              <div className="animation-fade left">
                <figure><img src="./assets/img/sec1_01@2x.png" alt="美人羅漢" /></figure>
              </div>
              <div className="animation-fade right">
                <h2>
                  <p>一箱30本入り (1本10g)</p>
                  <p>ピリッとした癖になるジンジャーティー味です</p>
                </h2>
                <p className="font__red price"><span>販売価格</span><span className="font__m">6,458円</span><span>(税込)</span></p>
                <a href="https://bijinrakan.jp" target="blank" className="btn red">ご購入はこちらから</a>
              </div>
            </div>
          </div>
        </section>

        <section id="section02">
          <div className="inner">
            <h2 className="pc animation-fade left">「基礎代謝を高めて美しさを保つ」ために</h2>
            <h2 className="sp animation-fade left"><span>女性は30代から</span><br /><span>本当に美しくなる</span></h2>
            <div className="desc animation-fade left">
              <p>
                職場や街中、日々様々な場面で出会う女性を見て感じることは「女性は30代に入ってから本当に美しくなる」と言うこと。<br />
                10代や20代の女性とは明らかに違う大人の女性としての魅力も増し、美に対する意識の持ち方次第ではその輝きは年を重ねても衰えず魅了し続けます。
              </p>

              <p>
                しかし20代から30代へと移り変わる時、カラダの中では確実に変化が生じているものもあります。
              </p>

              <p>
                それが「基礎代謝力」
              </p>

              <p>
                〔美人羅漢〕では「基礎代謝力」の向上をサポートしてカラダの内側から美を保つことを念頭に開発致しました。<br />
                もちろん美肌や美BODYに欠かす事のできない「プラセンタ(豚由来)」「コラーゲン(フィッシュコラーゲン)」も配合しております。
              </p>
            </div>
          </div>
        </section>

        <section id="section03" className="grad">
          <div className="background">
            <div>
              <h2 className="animation-fade down"><span>基礎代謝とは</span><span>生命を維持する上で消費される</span><br /><span>必要最小限のエネルギー</span></h2>
              <div className="inner">
                <div className="desc animation-fade up">
                  <p>
                    基礎代謝の女性のピークは10代前半から半ばまでと言われており、その後は年齢とともに下がっていきます。<br />
                    基礎代謝が下がると摂取したカロリーが消費されず、身体に脂肪が蓄積され、太りやすくなります。<br />
                    これが加齢とともに太りやすくする原因なのです。<br />
                    30代になると、それが顕著に現れる方もいるのではないでしょうか？
                  </p>
                  <p>
                    基礎代謝が高ければいつまでも若々しく美肌、美BODYでいられることが期待できるのです。<br /><br className="sp" />
                    そんな基礎代謝を高め、美しさを保つカラダづくりを目指す女性のために開発した「美容ゼリー」です。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="section04">
          <div className="inner">
            <h2 className="font__red"><span>美人羅漢が勧める</span><span>「3段階設計」</span></h2>
            <p>（管理栄養士「浅野まみこ」監修）</p>
            <div className="flex__list">
              <div className="flex__list__inner">
                <figure className="animation-fade left"><img src="./assets/img/point1@2x.png" alt="ビタミン全13種類を配合！" /></figure>
                <div className="animation-fade right">
                  <figure className="point"><img src="./assets/img/point1.svg" alt="Point1" /></figure>
                  <h3 className="font__red">ビタミン全13種類を配合！</h3>
                  <p className="desc">
                    体の基礎づくりに必要とされているビタミン13種類を配合しました。<br />
                    「ビタミンA,E,D,C,B1,B2,B6,B12,K,ナイアシン、葉酸、パントテン酸、ビチオン」
                  </p>
                </div>
              </div>
              <div className="flex__list__inner">
                <div className="animation-fade left">
                  <figure className="point"><img src="./assets/img/point2.svg" alt="Point2" /></figure>
                  <h3 className="font__red">美しさを助ける成分を配合！</h3>
                  <p className="desc">
                    体の内側から美しさをサポートするプラセンタ・コラーゲン・葉酸(ビタミンB群の一種)を配合。
                  </p>
                </div>
                <figure className="animation-fade right"><img src="./assets/img/point2@2x.png" alt="美しさを助ける成分を配合！" /></figure>
              </div>
              <div className="flex__list__inner">
                <figure className="animation-fade left"><img src="./assets/img/point3@2x.png" alt="健康を保つ5つの自然由来の成分を配合！" /></figure>
                <div className="animation-fade right">
                  <figure className="point"><img src="./assets/img/point3.svg" alt="Point3" /></figure>
                  <h3 className="font__red">健康を保つ5つの自然由来の成分を配合！</h3>
                  <p className="desc">
                    マカ、ナツメ、クコ、ショウガ、大豆を配合。<br />
                    これらは体のバランスを整えるのに必要。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="section05">
          <div className="inner__flex bg__grad">
            <div>
              <h2 className="font__white animation-fade down">これらの成分が<br />スティック1本に全て配合</h2>
              <p className="font__white price pc animation-fade down">販売価格 <span className="font__m">6,458円</span> (税込)</p>
              <figure className="sp animation-fade down"><img src="./assets/img/sec5_01.png" alt="美人羅漢" /></figure>
              <a href="https://bijinrakan.jp" target="blank" className="btn white animation-fade up">ご購入はこちらから</a>
            </div>
            <figure className="pc animation-fade right"><img src="./assets/img/sec5_01.png" alt="美人羅漢" /></figure>
          </div>
        </section>

        <section id="section06">
          <div className="inner">
            <div className="balloon animation-fade up">
              <div>毎日続けてもらう</div>
            </div>
            <h2 className="font__red">美人羅漢のこだわり</h2>
            <div className="flex">
              <div className="animation-fade left">
                <h3 className="font__red">「ピリっ」とパンチの効いたジンジャーティー味</h3>
                <p>
                  美人羅漢はコクのある甘さだけではなく、ショウガの風味をしっかりと感じることができるジンジャーティー味。<br />
                  はじめはジンジャーティー味の濃厚さにびっくりするかもしれませんが、癖になる味です。
                </p>
              </div>
              <figure className="animation-fade right">
                <img src="./assets/img/sec6_01.png" alt="ジンジャー" />
              </figure>
            </div>
            <div className="flex">
              <div className="animation-fade left">
                <h3 className="font__red">持ち運びに便利なスティックタイプ</h3>
                <p>
                  個別包装でスティック状にした美容ゼリーに仕上げてあります。<br />
                  朝食代わりに、スポーツの後に、いつでもどこでも気軽にお召し上がりいただけます。
                </p>
              </div>
              <figure className="animation-fade right">
                <img src="./assets/img/sec6_02.png" alt="美人羅漢 スティックタイプ" />
              </figure>
            </div>
          </div>
        </section>

        <section id="section07">
          <div className="inner">
            <h2 className="font__white">こんな方に飲んでもらいたい</h2>
            <div className="flex__3">
              <div>
                <figure className="animation-fade up"><img src="./assets/img/sec7_01.png" alt="カラダを温める生活" /></figure>
                <p className="font__red">カラダを温める生活を普段からこころがけている。</p>
              </div>
              <div>
                <figure className="animation-fade up"><img src="./assets/img/sec7_02.png" alt="基礎代謝量" /></figure>
                <p className="font__red">カラダの基礎代謝量も高く保ちたいと、普段から適度な運動をしている。</p>
              </div>
              <div>
                <figure className="animation-fade up"><img src="./assets/img/sec7_03.png" alt="カラダに優しい自然成分" /></figure>
                <p className="font__red">カラダに優しい自然成分を定期的にとりいれたいと思っている。</p>
              </div>
            </div>
          </div>
        </section>

        <section id="section08">
          <div className="inner">
            <h2 className="font__white">モニター様からの声</h2>
            <ul className="flex__li">
              <li className="animation-fade right">
                <div>1</div>
                <figure><img src="./assets/img/icon01.svg" alt="モニター様" /></figure>
                <p>
                  コンビニサラダと一緒に食べても味がマッチして美味しかった<br />
                  (30代・主婦)
                </p>
              </li>
              <li className="animation-fade right">
                <div>2</div>
                <figure><img src="./assets/img/icon02.svg" alt="モニター様" /></figure>
                <p>
                  今まで朝食抜きの生活をしていたのですが、美人羅漢を朝食代わりに2本食べる習慣がつきました。<br />
                  （20代・女性サービス業）
                </p>
              </li>
              <li className="animation-fade right">
                <div>3</div>
                <figure><img src="./assets/img/icon03.svg" alt="モニター様" /></figure>
                <p>
                  食べ始めてから3日目くらいに、朝起きた時の肌のハリに違いを感じました。しばらく続けてみたいと思いました。<br />
                  （30代・女性サービス業）
                </p>
              </li>
              <li className="animation-fade right">
                <div>4</div>
                <figure><img src="./assets/img/icon04.svg" alt="モニター様" /></figure>
                <p>
                  ジンジャーティーの味が口に広がってクセになる味です。<br />
                  いつも持ち歩いていつでも食べられるのが気に入っています。<br />
                  （40代・主婦）
                </p>
              </li>
            </ul>
          </div>
        </section>

        <section id="section09">
          <div className="inner">
            <h2 className="font__white">まずは3ヶ月続けてみませんか？</h2>
            <p className="font__white">
              一箱30本入り (1本10g)<br />
              ピリッとした癖になるジンジャーティー味です
            </p>
            <a href="https://bijinrakan.jp" target="blank" className="btn red">ご購入はこちらから</a>
          </div>
        </section>

        <footer></footer>
      </div>
    );
  }
}

export default App;
